
import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
// import { FiArrowRight } from 'react-icons/fi'
import Areas from '../../components/AreasExpertise'
import BlogForm from './Blog/BlogForm'

const Services = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <StaticQuery
      query={graphql`
      query {
        Banner: file(relativePath: { eq: "StaffingServices1.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
      } 
    `}

      render={data => {
        const imageData = data.Banner.childImageSharp.fluid
        const {
          // buttonLabel,
          className
        } = props;
        return (
          <Layout path="/services/">
            {/* <section>
            <div className="banner_section">
              <Img
                fluid={imageData}
                className="startup_background"
              ></Img>
            </div>
            <div className="first_banner">
              <Container>
                <Row>
                  <Col sm="12" md={{ size: 7, offset: 6 }}>
                    <Fade right>
                      <div>
                        <h1>We offer the following types of IT staffing services so that our clients can choose the one that is best for their needs.</h1>
                      </div>
                    </Fade>
                  </Col>
                </Row>
              </Container>
            </div>
          </section> */}
            <section className="banner_min">
              <div className="banner_section">
                <Img
                  fluid={imageData}
                  className="startup_background"
                ></Img>
              </div>
              <div className="banner_blur5">
                <div className="first_banner">
                  <Container>
                    <Row>
                      <Col sm="12" md={{ size: 7, offset: 6 }}>
                        <div>
                          <Fade right>
                            <h1>Find the right opportunity through the right vendor</h1>
                            {/* <h1>We offer the following types of IT staffing services so that our clients can choose the one that is best for their needs.</h1> */}
                          </Fade>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </section>
            <section className="service_section">
              <Container>
                <div className="text_center">
                  {/* <h3>We offer the following types of IT staffing services so that our clients can choose the one that is best for their needs.</h3> */}
                  <h3>We offer the following types of staffing services so that our clients can choose the one that fits them best</h3>
                  <br />
                </div>
                <Row>
                  <Col md="6">
                    <div className="service_card service_card1">
                      <h3 className="text_color">Contract IT Staffing</h3>
                      <p>Our clients rely on our contract staffing services to add critical IT talent to their teams for short, medium, or long term assignments.</p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="service_card service_card2">
                      <h3 className="text_color">Direct IT Staffing</h3>
                      <p>Our technical recruiters are experts at finding the right IT talent for our clients to hire immediately without engaging them on a contract basis.</p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="service_card service_card3">
                      <h3 className="text_color">Statement of Work (SOW)</h3>
                      <p>Our clients define the project, scope of work, timelines, deliverables, and special requirements. Our team of IT professionals delivers the results.</p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="service_card service_card4">
                      <h3 className="text_color">Contract-to-Hire IT Staffing</h3>
                      <p>This “try before you buy” approach allows companies to engage IT talent on a contract basis before deciding on whether to hire them on as a direct employee.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section>
              <Areas />
            </section>
            {/* <section>
            <div className="startup_bg">
              <div className="startup_section">
                <Container>
                  <Row>
                    <Col md="12">
                      <h3>Big Data Staffing</h3>
                      <p>The immense wealth of intelligence captured by enterprises made harnessing and analyzing large data sets the key basis of competition and growth. Big Data can unlock significant value by making information transparent and usable at much higher frequency, expose variability and boost performance, allow ever-narrower customer segmentation, and improve decision-making.</p>
                      <p>Trident’s Big Data Staffing Practice enables our clients to tap into previously underutilized, isolated data silos and help business stakeholders make crucial decisions in daily and long-term business goals.</p>
                      <p>Trident has worked with global organizations in providing effective Big Data consultants that have helped clients to:</p>
                      <ul>
                        <li>Derive revenue growth</li>
                        <li>Reduce costs</li>
                        <li>Identify new business opportunities</li>
                        <li>Respond to competitive threats</li>
                      </ul>

                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </section> */}
            <section className="footer_section">
              <Container>
                <Row>
                  <Col md="12">
                    <h1>Find out what Trident can do for you.</h1>
                    <div className="footer_button">
                      <a href onClick={toggle}>Contact Us</a>
                    </div>
                    <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                      <ModalBody >
                        <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Container>
            </section>
            {/* <BlogForm refItem = {()=>{}} title = "Find out what Trident can do for you." redirectTitle="Services" pathname={props.location.pathname}/> */}

          </Layout>
        )
      }}
    />
  )
}
const StyledBackgroundSection = styled(Services)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection
